// import React, { useEffect, useState } from 'react';
// import { Route, Link, BrowserRouter, Routes } from 'react-router-dom';
// import Dashboard from './Pages/Dashboard';
// import Analytics from './Pages/Analytics';
// import SelectProduct from './Pages/SelectProduct';
// import OrdersPage from './Pages/OrdersPage';
// import DesignUpload from './Pages/DesignUpload'
// import SignupPage from './Pages/Signup';
// import Login from './Pages/Login';
// import ForgetPassword from './Pages/ForegetPassword';
// import Products from './Pages/Products';
// import DesignReview from './Pages/DesignReview';
// import SelectTShirt from './Pages/SelectTshirt';
// import TransactionsPage from './Pages/Transaction';
// import { ModalProvider } from './components/ModalContext'
// import PaidOrdersPage from './Pages/PaidOrdersPage';

// const App = () => {

//   return (
//     <ModalProvider>

//     <BrowserRouter>
      
//       <Routes>
//         <Route path="/OrdersPage" element={<OrdersPage />} />
//         <Route path='/dashboard' element={<Dashboard/>} />
//         <Route path='/signup' element={<SignupPage/>} />
//         <Route path='/' element={<Login/>} />
//         <Route path='/login/forgetPassword' element={<ForgetPassword/>} />
//         <Route path='/Analytics' element={<Analytics/>} />
//         <Route path='/SelectProduct' element={<SelectProduct/>} />
//         <Route path='/SelectTshirt' element={<SelectTShirt/>} />
//         <Route path='/DesignUpload' element={<DesignUpload/>} />
//         <Route path='/DesignReview' element={<DesignReview/>} />
//         <Route path='/Products' element={<Products/>} />
//         <Route path='/Transaction' element={<TransactionsPage/>} />
//         <Route path='/PaidOrders' element={<PaidOrdersPage/>} />
//       </Routes>
//     </BrowserRouter>
//     </ModalProvider>
//   );
// };

// export default App;



// src/App.js
import React from 'react';
import { useState } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Analytics from './Pages/Analytics';
import SelectProduct from './Pages/SelectProduct';
import OrdersPage from './Pages/OrdersPage';
import DesignUpload from './Pages/DesignUpload';
import SignupPage from './Pages/Signup';
import Login from './Pages/Login';
import ForgetPassword from './Pages/ForegetPassword';
import Products from './Pages/Products';
import DesignReview from './Pages/DesignReview';
import SelectTShirt from './Pages/SelectTshirt';
import TransactionsPage from './Pages/Transaction';
import { ModalProvider } from './components/ModalContext';
import PaidOrdersPage from './Pages/PaidOrdersPage';
import AuthRoute from './components/AuthRoute'; // Import your AuthRoute
import TermsConditions from './Pages/TermsConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import OrderSample from './Pages/OrderSample';
import SampleOrder from './Pages/OrderSample';
import CreateOrder from './Pages/CreateOrder';
import './App.css'
import BookSample from './Pages/BookSample';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShippingPolicy from './Pages/ShippingPolicy';
import Cancellation from './Pages/Cancellation';
import ContactUs from './Pages/ContactUs';
import StoreUrlForm from './Pages/StoreUrlForm';
import SettingsPage from './Pages/Setting';
import CreateOrderList from './Pages/CreateOrderList';
import Branding from './Pages/Branding';
import UserRTOInventory from './Pages/RTOinventory';
import SelectTShirtNew from './Pages/SelectTshirtnew';
import DesignUploadNew from './Pages/DesignUploadnew';
import ProductsNew from './Pages/ProductsNew';
import OrdersPageNew from './Pages/OrdersPageNew';
import SalesInvoice from './Pages/SalesInvoice';
import NDRreports from './Pages/NDRreports';
import Delivered from './Pages/Delivered';
import Return from './Pages/Return';
import RTOOrders from './Pages/RTOOrders';
import CODRemittance from './Pages/CODRemittance';
import PurchaseInvoice from './Pages/PuchaseInvoice';
import NeckLabels from './Pages/NeckLabels.jsx'
import BrandingElements from './Pages/BrandingElements.jsx';
import InTransit from './Pages/InTransit.jsx';


const App = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  return (
     
    <>
     
     {/* <div>
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </div> */}
    <ModalProvider>
      <BrowserRouter>
        <Routes>
          

          <Route path="/store-connect" element={<StoreUrlForm/>} />
          <Route path="/" element={<Login/>} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login/forgetPassword" element={<ForgetPassword />} />
           
          {/* Protected Routes */}
          <Route path="/dashboard" element={<AuthRoute element={Dashboard} />} />
          <Route path="/OrdersPage" element={<AuthRoute element={OrdersPage} />} />
          {/* <Route path="/Analytics" element={<AuthRoute element={Analytics} />} /> */}
          <Route path="/SelectProduct" element={<AuthRoute element={SelectProduct} />} />
          <Route path="/SelectTshirt" element={<AuthRoute element={SelectTShirt} />} />
          <Route path="/DesignUpload" element={<AuthRoute element={DesignUpload} />} />
          <Route path="/DesignReview" element={<AuthRoute element={DesignReview} />} />
          <Route path="/Products" element={<AuthRoute element={Products} />} />
          <Route path="/Transaction" element={<AuthRoute element={TransactionsPage} />} />
          <Route path="/PaidOrders" element={<AuthRoute element={PaidOrdersPage} />} />
          <Route path="/setting" element={<AuthRoute element={SettingsPage} />} />

          {/* <Route path="/store-connect" element={<AuthRoute element={StoreUrlForm} />} /> */}


          <Route path="/terms-and-conditions" element={<TermsConditions/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/shipping-policy" element={<ShippingPolicy/>} />
          <Route path="/cancellation-policy" element={<Cancellation/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/Order-sample" element={<AuthRoute element={SampleOrder} />} />
          <Route path="/Book-a-sample" element={<AuthRoute element={BookSample} />} />
          <Route path="/create-order" element={<AuthRoute element={CreateOrder} />} />
          <Route path="/create-order-list" element={<AuthRoute element={CreateOrderList} />} />
          <Route path="/branding" element={<AuthRoute element={Branding} />} />

          <Route path="/RTo-inventory" element={<AuthRoute element={UserRTOInventory} />} />
          <Route path="/ndr-reports" element={<AuthRoute element={NDRreports} />} />
          <Route path="/delivered" element={<AuthRoute element={Delivered} />} />
          <Route path="/intransit" element={<AuthRoute element={InTransit} />} />
          <Route path="/returns" element={<AuthRoute element={Return} />} />
          <Route path="/rto-orders" element={<AuthRoute element={RTOOrders} />} />
          <Route path="/cod-remittance" element={<AuthRoute element={CODRemittance} />} />

          <Route path="/design-uploadNew" element={<AuthRoute element={DesignUploadNew} />} />
          <Route path="/select-tshirt" element={<AuthRoute element={SelectTShirtNew} />} />
          <Route path="/products-new" element={<AuthRoute element={ProductsNew} />} />
          <Route path="/Orders-new" element={<AuthRoute element={OrdersPageNew} />} />
          <Route path="/Sales-invoice" element={<AuthRoute element={SalesInvoice} />} />
          <Route path="/Purchase-invoice" element={<AuthRoute element={PurchaseInvoice} />} />


          <Route path="/necklabels" element={<AuthRoute element={NeckLabels} />} />
          <Route path="/branding/branding-elements" element={<AuthRoute element={BrandingElements} />} />

        </Routes>
      </BrowserRouter>
    </ModalProvider>
    </>
  );
};

export default App;
